/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $('.photo-grid').isotope({
          // options...
          itemSelector: '.grid-item',
          layoutMode: 'masonry',
          masonry: {
            columnWidth: 132,
            gutter: 14,
            isFitWidth: true
          }
        });
        $.fn.isOnScreen = function(){
          var win = $(window);

          var viewport = {
              top : win.scrollTop(),
              left : win.scrollLeft()
          };
          viewport.right = viewport.left + win.width();
          viewport.bottom = viewport.top + win.height();

          var bounds = this.offset();
          bounds.right = bounds.left + this.outerWidth();
          bounds.bottom = bounds.top + this.outerHeight();

          return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
        };

        // GASPAR'S COUNT ANIMATION START

        $('.our-story-numbers').each(function(){
          if ( $(this).find('.story-item .title').isOnScreen() ) {
            if ( !$(this).hasClass('animated') ) {
              $(this).addClass('animated');
              var options = {
                useEasing : true,
                useGrouping : true,
                separator : '',
                decimal : '',
                prefix : '',
                suffix : ''
              };
              var animate_numbers;
              $(this).find('.title').each(function(){
                options.separator = $(this).attr('data-separator');
                options.suffix = $(this).attr('data-suffix');
                endval = $(this).attr('data-endval');
                duration = $(this).attr('data-duration');
                animate_numbers = new CountUp($(this)[0], 0, endval, 0, parseInt(duration), options);
                animate_numbers.start();
              });
            }
          }
        });

        $(document).on('scroll',function(){
          $('.our-story-numbers').each(function(){
            if ( $(this).find('.story-item .title').isOnScreen() ) {
              if ( !$(this).hasClass('animated') ) {
                $(this).addClass('animated');
                var options = {
                  useEasing : true,
                  useGrouping : true,
                  separator : '',
                  decimal : '',
                  prefix : '',
                  suffix : ''
                };
                var animate_numbers;
                $(this).find('.title').each(function(){
                  options.separator = $(this).attr('data-separator');
                  options.suffix = $(this).attr('data-suffix');
                  endval = $(this).attr('data-endval');
                  duration = $(this).attr('data-duration');
                  animate_numbers = new CountUp($(this)[0], 0, endval, 0, parseInt(duration), options);
                  animate_numbers.start();
                });
              }
            }
          });
        });
        var template_settings = {
          //filters: '#onebrand-template-categories',
          filters: '',
          loadMore: '',
          loadMoreAction: '',
          layoutMode: 'grid',
          defaultFilter: '*',
          animationType: 'scaleSides',
          gapHorizontal: 33,
          gapVertical: 30,
          gridAdjustment: 'responsive',
          mediaQueries: [{
              width: 1500,
              cols: 3
          }, {
              width: 768,
              cols: 3
          }, {
              width: 480,
              cols: 2
          }, {
              width: 320,
              cols: 1
          }],
          caption: 'zoom',
          displayType: 'fadeIn',
          displayTypeSpeed: 400,

          // singlePage popup
          singlePageDelegate: '.cbp-singlePage',
          singlePageDeeplinking: true,
          singlePageStickyNavigation: true,
          singlePageCounter: '<div class="cbp-popup-singlePage-counter">{{current}} of {{total}}</div>',
          singlePageCallback: function(taxonomy, element) {
              // to update singlePage content use the following method: this.updateSinglePage(yourContent)
              var t = this;
              $.post(
                onebrand_config.ajax_url,
                {
                  action: "onebrand_get_templates",
                  taxonomy: taxonomy,
                },
                function(msg)
                {
                  t.updateSinglePage(msg);
                  $('.template_div_small ul').matchHeight();
                }
              );

          }
        };
        var portfolio_settings = {
          filters: '#js-filters-juicy-projects',
          loadMore: '',
          loadMoreAction: '',
          layoutMode: 'grid',
          defaultFilter: '*',
          animationType: 'scaleSides',
          gapHorizontal: 33,
          gapVertical: 30,
          gridAdjustment: 'responsive',
          mediaQueries: [{
              width: 1500,
              cols: 3
          }, {
              width: 768,
              cols: 3
          }, {
              width: 480,
              cols: 2
          }, {
              width: 320,
              cols: 1
          }],
          caption: 'zoom',
          displayType: 'fadeIn',
          displayTypeSpeed: 400,

          // singlePage popup
          singlePageDelegate: '.cbp-singlePage',
          singlePageDeeplinking: true,
          singlePageStickyNavigation: true,
          singlePageCounter: '<div class="cbp-popup-singlePage-counter">{{current}} of {{total}}</div>',
          singlePageCallback: function(post_id, element) {
              // to update singlePage content use the following method: this.updateSinglePage(yourContent)
              var t = this;
              $.post(
                onebrand_config.ajax_url,
                {
                  action: "onebrand_get_portfolio",
                  post_id: post_id,
                  type: $(this).attr('data-postclass'),
                },
                function(msg)
                {
                  t.updateSinglePage( msg );
                }
              );
          }

        }
        // GASPAR'S COUNT ANIMATION END
        //
        var hero_slider = $('.header-jumbo .owl-carousel');
        hero_slider.on('initialized.owl.carousel', function(event) {
          var video = hero_slider.find('.owl-item.active video');
          if ( ! video.hasClass('video_started') ) {
           video.addClass('video_started');
           video[0].play();
         }
        });
          hero_slider.owlCarousel({
            loop:true,
            margin:0,
            callbacks: true,
            animateOut: 'fadeOut',
            animateIn: 'fadeIn',
            nav:true,
            navText: ['<i class="icon icon-arrow-left"></i>','<i class="icon icon-arrow-right"></i>'],
            items: 1,
            autoplay:true,
            smartSpeed:850,
            autoplayTimeout:5000,
            autoplayHoverPause:true,
        });


        $('.testimonials.section .im-testimonial-slider').owlCarousel({
            loop:true,
            margin:0,
            nav:true,
            navText: ['<i class="icon icon-arrow-left"></i>','<i class="icon icon-arrow-right"></i>'],
            items: 1,
            autoplay:true,
            smartSpeed:1200,
            autoplayTimeout:10000,
            autoplayHoverPause:true
        });

        function portfolio_cube() {
          (function($, window, document, undefined) {
              'use strict';

            $('#js-grid-open-positions').cubeportfolio({
              filters: '#js-grid-slider-testimonials',
              loadMore: '',
              loadMoreAction: 'click',
              layoutMode: 'grid',
              defaultFilter: '*',
              animationType: 'scaleSides',
              gapHorizontal: 33,
              gapVertical: 30,
              gridAdjustment: 'responsive',
              mediaQueries: [{
                  width: 900,
                  cols: 4
              }, {
                  width: 767,
                  cols: 2
              }, {
                  width: 480,
                  cols: 2
              }, {
                  width: 320,
                  cols: 1
              }],
              caption: 'zoom',
              displayType: 'fadeIn',
              displayTypeSpeed: 400,

              // lightbox
              lightboxDelegate: '.cbp-lightbox',
              lightboxGallery: true,
              lightboxTitleSrc: 'data-title',
              lightboxCounter: '',
            });
            $('.portfolio-section #js-grid-juicy-projects').cubeportfolio( 'init', portfolio_settings, function(){
              $('.portfolio-section #js-grid-slider-testimonials').cubeportfolio({
                layoutMode: 'slider',
                drag: true,
                auto: false,
                autoTimeout: 5000,
                autoPauseOnHover: true,
                showNavigation: true,
                showPagination: false,
                rewindNav: true,
                scrollByPage: false,
                gridAdjustment: 'responsive',
                mediaQueries: [{
                    width: 1100,
                    cols: 4
                }, {
                  width: 900,
                    cols: 4
                }, {
                    width: 600,
                    cols: 3
                }, {
                    width: 480,
                    cols: 2
                }, {
                    width: 320,
                    cols: 2
                }],
                gapHorizontal: 0,
                gapVertical: 0,
                caption: '',
                displayType: 'default',
              });
            });
            $('#onebrand-templates').cubeportfolio( 'init', template_settings, function(){
              $('#onebrand-templates-slider-testimonials').cubeportfolio({
                layoutMode: 'slider',
                drag: true,
                auto: false,
                autoTimeout: 5000,
                autoPauseOnHover: true,
                showNavigation: true,
                showPagination: false,
                rewindNav: true,
                scrollByPage: false,
                gridAdjustment: 'responsive',
                mediaQueries: [{
                    width: 1100,
                    cols: 4
                }, {
                  width: 900,
                    cols: 4
                }, {
                    width: 600,
                    cols: 3
                }, {
                    width: 480,
                    cols: 2
                }, {
                    width: 320,
                    cols: 2
                }],
                gapHorizontal: 0,
                gapVertical: 0,
                caption: '',
                displayType: 'default',
              });
            });
          })(jQuery, window, document);
          $('.cbp-l-slider-testimonials-wrap .cbp-filter-item').on('click',function(){
            $('.cbp-l-slider-testimonials-wrap .cbp-filter-item').removeClass('cbp-filter-item-active');
            $(this).addClass('cbp-filter-item-active');
          });
        }

        portfolio_cube();


        $('#hero-video')
          .on('click',function(){
            var video = document.getElementById('hero-video');
            if (video.paused === false) {
                video.pause();
                $('.video-control').slideToggle('fast');
            } else {
                video.play();

            }
          })
          .bind("ended", function() {
            $('.video-control').slideToggle('fast');
          });

        $('.video-control .icon-play').on('click',function(){
          var video = document.getElementById('hero-video');
          if (video.paused === false) {
              video.pause();
          } else {
              video.play();
          }
          $('.video-control').slideToggle('fast');
        });

        $('.portfolio-section .onebrand-load-more-btn').on('click',function(e){
          e.preventDefault();
          $(this).find('span').hide();
          $(this).find('.cbp-l-loadMore-loadingText').show();
          var id = '#js-grid-juicy-projects',
              paged = $(this).attr('data-paged'),
              max_num = $(this).attr('data-maxnumpages'),
              next_page = parseInt(paged) + 1,
              link = $(this).attr('data-pageurl')+'?paged='+next_page;
          $('.portfolio-container .load-more-container').load(link +' '+ id + ' .cbp-item', function(){
            $(id).cubeportfolio('appendItems', $('.portfolio-container .load-more-container').html());
            $('.portfolio-section .onebrand-load-more-btn').find('span').hide();
            $('.portfolio-section .onebrand-load-more-btn').find('.cbp-l-loadMore-defaultText').show();
          });

          if ( max_num == parseInt( next_page ) ) {
            $(this).hide();
          } else {
            $(this).attr('data-paged', next_page);
          }
        });
        $('#onebrand-template-categories #onebrand-templates-slider-testimonials').on('click','.cbp-filter-item',function(){
          link = $(this).attr('data-pageurl')+'?template_category='+$(this).attr('data-filter');
          id = '#onebrand-templates';
          $(id).cubeportfolio('destroy');
          $('.templates-archive .load-more-container').load( link +' '+ id + ' .cbp-item', function(){
            template_settings['displayType'] = 'fadeInToTop';
            $(id).html($('.portfolio-container.templates-archive .load-more-container').html()).cubeportfolio(template_settings);
            //$(id).cubeportfolio('appendItems', $('.portfolio-container.templates-archive .load-more-container').html());
          });
        });
        // CONTENT MODAL
        var appendthis =  ("<div class='onebrand-modal-overlay js-modal-close'></div>");
        $('a[data-toggle="im-modal"]').click(function(e) {
          e.preventDefault();
          $('body').addClass('onebrand-modal-open');
          var $btn = $(this).button('loading');
            //$(".js-modalbox").fadeIn(500);
          var contentmodalBox = $(this).attr('data-modal-content-id');
          $('#im_modal'+contentmodalBox).parent().append(appendthis);
          $('header.with_ubermenu .navbar').css('z-index',1);
          $(".onebrand-modal-overlay").fadeTo(200, 0.9);
          $('#im_modal'+contentmodalBox).fadeIn( 200 );
          $btn.button('reset');

        });
        $('body').on('click','.js-modal-close',function(e){
          e.preventDefault();
          $(".onebrand-content-modal-box, .onebrand-modal-overlay").fadeOut(200, function() {
            $(".onebrand-modal-overlay").remove();
            $('header.with_ubermenu .navbar').css('z-index',1000);
            $('body').removeClass('onebrand-modal-open');
          });
        });
      	$('#newsletter_signup').submit(function(){
      		var mailchimpform = $(this);
          var $btn = $('#newsletter_signup .btn');
          $btn.button('loading');
      		$.ajax({
      			url:mailchimpform.attr('action'),
      			type:'POST',
      			data:mailchimpform.serialize(),
      			success:function(data){
      				alert(data);
              $btn.button('reset');
              mailchimpform[0].reset();
      			}
      		})
          return false;
      	});

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'team': {
      init: function() {

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
